import React from "react"
import { graphql, Link } from "gatsby"
import statWinner from "../../js/statWinner"
//import formatNumComma from "../../js/formatNumComma"
import gamesPerStat from "../../js/gamesPerStat"
import statPer90 from "../../js/statPer90"
import Icon from '../../components/Utilities/Icon'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'

class OptaAssists extends React.Component {

  state = {
    p90: false,
    showTopLevel: false,
  };

  manageP90Toggle = () => {
    this.setState(state => ({ p90: !state.p90 }));
  };

  manageTopLevelToggle = () => {
    this.setState(state => ({ showTopLevel: !state.showTopLevel }));
  };

  render() {

    const pageTitle = "Assists (Opta)"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0] / mdata.filter(d => d.node.competition === comp).map(d => d.node.assists)[0]).toFixed(1)
      ) : (
        parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.apps)[0] / rdata.filter(d => d.node.competition === comp).map(d => d.node.assists)[0]).toFixed(1)
      )
    }

    const comps = [
      {
        "comp": "All Time Career",
        "mSubStat": subStat('m', 'All Time Career'),
        "rSubStat": subStat('r', 'All Time Career'),
        "btnText": "Yearly Breakdown"
      },
      {
        "comp": "All Time Career Europe",
        "title": "All Time Career *",
        "mSubStat": subStat('m', 'All Time Career Europe'),
        "rSubStat": subStat('r', 'All Time Career Europe'),
        "btnText": "Yearly Breakdown",
        "topLevel": true
      },
      {
        "comp": "All Time Club",
        "mSubStat": subStat('m', 'All Time Club'),
        "rSubStat": subStat('r', 'All Time Club'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Club Europe",
        "title": "All Time Club *",
        "mSubStat": subStat('m', 'All Time Club Europe'),
        "rSubStat": subStat('r', 'All Time Club Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "League",
        "mSubStat": subStat('m', 'All Time League'),
        "rSubStat": subStat('r', 'All Time League'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time League Europe",
        "title": "League *",
        "mSubStat": subStat('m', 'All Time League Europe'),
        "rSubStat": subStat('r', 'All Time League Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "Champions League",
        "mSubStat": subStat('m', 'All Time Europe'),
        "rSubStat": subStat('r', 'All Time Europe'),
        "btnText": "Season Breakdown",
        "topLevel": "both"
      },
      {
        "comp": "Other Club Cups",
        "mSubStat": subStat('m', 'All Time Cups'),
        "rSubStat": subStat('r', 'All Time Cups'),
        "btnText": "Season Breakdown"
      },
      {
        "comp": "All Time Cups Europe",
        "title": "Other Club Cups *",
        "mSubStat": subStat('m', 'All Time Cups Europe'),
        "rSubStat": subStat('r', 'All Time Cups Europe'),
        "btnText": "Season Breakdown",
        "topLevel": true
      },
      {
        "comp": "International",
        "mSubStat": subStat('m', 'All Time Internationals'),
        "rSubStat": subStat('r', 'All Time Internationals'),
        "btnText": "Yearly Breakdown",
        "topLevel": "both"
      },
    ]

    let [{
      assists: mAllTimeAssists,
      apps: mAllTimeApps,
    }] = mdata.filter(d => d.node.competition === "All Time Career").map(d => d.node)
  
    let [{
      assists: rAllTimeAssists,
      apps: rAllTimeApps,
    }] = rdata.filter(d => d.node.competition === "All Time Career").map(d => d.node)

    const rCLAssists = rdata.filter(d => d.node.competition === "All Time Champions League").map(d => d.node.assists)
    const mCLAssists = mdata.filter(d => d.node.competition === "All Time Champions League").map(d => d.node.assists)


    // Below stuff is for the assists breakdown table
    let mdatax = this.props.data.allSheetMessiAdditionalSeasonStats.edges
    let rdatax = this.props.data.allSheetRonaldoAdditionalSeasonStats.edges

    // This line is required due to node support issues for Object.fromEntries
    Object.fe = arr => Object.assign({}, ...Array.from(arr, ([k, v]) => ({[k]: v}) ));

    // Map over the two arrays and prefix all the keys with m or r
    mdatax = mdatax.map(entry => { return Object.fe( Object.entries(entry).map(([key, value]) => [`m${key}`, value] ) ) })
    rdatax = rdatax.map(entry => { return Object.fe( Object.entries(entry).map(([key, value]) => [`r${key}`, value] ) ) })

    // Combine the two arrays so we have both players' stats in a single object per year/season
    const bothData = mdatax.map((item, key) => Object.assign({}, item, rdatax[key]))


    const shortSeason = (season) => {
      return season.replace(/-/g, '/').replace(/20/g,'').replace('/21','20/21').replace('19/','19/20')
    }

    const shortComp = (comp) => {
      return comp.replace('Champions League','UCL').replace('All Competitions','Total')
    }

    const comp = (data,p,comp = "All Competitions") => {
      return p == "m" ? data.filter(d => d.mnode.competition === comp) : data.filter(d => d.rnode.competition === comp)
    }


    return (

      <Layout>
        <SEO
          title={`Opta Assists - Messi and Ronaldo All Time ${pageTitle}`}
          description={`Assists are ambiguous and vary greatly by source. We provide the official Opta assist numbers for Messi and Ronaldo and show you where other sites are going wrong!`}
          canonicalPath={`/detailed-stats/opta-assists/`}
        />

        <h1>Opta Assists <span className="sr-only">- Messi vs Ronaldo All Time Assists (Opta)</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-2" onChange={this.manageTopLevelToggle}>
          <label className={`flex items-center cursor-pointer px-3 sm:px-5 pt-3 pb-4 bg-noir-lighten10 border ${this.state.showTopLevel ? 'border-highlight' : 'border-noir-lighten10'}`}>
            <input type="checkbox" checked={this.state.showTopLevel ? true : false} />
            <span className="ml-2 text-xs sm:text-sm">Only include club stats for European clubs</span>
          </label>
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

        {comps.map(d => (
          <DetailedStatBars
            key={d.comp}
            data={this.props.data}
            competition={d.comp}
            title={d.title ? d.title : d.comp}
            topLevel={d.topLevel}
            showTopLevel={this.state.showTopLevel}
            playerTitleHidden={`${d.comp} ${pageTitle}`}
            statType={pageTitle}
            btnText={d.btnText}
            statView={['Totals', "Frequency"]}
            mSubStat={`makes an assist every ${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'} games`}
            rSubStat={`makes an assist every ${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'} games`}
            subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-', 'lowWins')}
          />
        ))}

        </div>

        <CopyBlock>
          <h2>Messi & Ronaldo Opta Assists</h2>

          <InfoBar />

          <p>We get a <em>lot</em> of questions about assist stats. The problem is that assists are, by nature, very ambiguous and subsequently many different websites and stats providers have slightly different numbers.</p>

          <p>This website doesn't use Opta as a direct data source, however we do try to align our assist stats with Opta's as much as possible, as they are the industry leading stats provider.</p>

          <p><strong>Opta</strong> are the most reliable stats source and the closest we've got to an "official" stats provider in football. They provide stats for the BBC, the Premier League, WhoScored, Squawka, and many other major media outlets.</p>

          <p>The following is <strong className="text-highlight">Opta's assist definition</strong>:</p>

          <blockquote>
            <p>The final touch (pass, pass-cum-shot or any other touch) leading to the recipient of the ball scoring a goal. If the final touch (as defined in bold) is deflected by an opposition player, the initiator is only given a goal assist if the receiving player was likely to receive the ball without the deflection having taken place. Own goals, directly taken free kicks, direct corner goals and penalties do not get an assist awarded.</p>
          </blockquote>

          <p><strong>Transfermarkt</strong> on the other hand are much more loose with their assist definition; they count deflected passes, rebounded shots, penalties won, and own goal assists; however they are seemingly very inconsistent in terms of awarding these assists and there are subsequently many holes in their data.</p>

          <p>For complete assist totals of all types, including deflected passes, goalkeeper rebounds, post rebounds, penalties won (scored by others) and own goal assists, take a look at our <Link to={`/detailed-stats/all-assists/`} className="text-highlight underline">All Assists (+ non-Opta)</Link> page.</p>

          <p>You can also view a full list of all assists that Transfermarkt are missing from their records over on our <Link to={`/info/`} className="text-highlight underline">Info</Link> page.</p>
          
          <h2>Set Piece / Open Play Assists</h2>

          <div className="" onChange={this.manageP90Toggle}>
            <label className={`flex w-full items-center p-2 cursor-pointer`}>
              <input type="checkbox" checked={this.state.p90 ? true : false} />
              <span className="ml-2">Per 90 Stats</span>
            </label>
          </div>

          <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
            <table className="w-full">
              <thead className="text-left">
              <tr>
                <th className="border-gray-700"></th>
                <th className="border-gray-700" colSpan="2">Total</th>
                <th className="border-gray-700" colSpan="2">Set Piece</th>
                <th colSpan="2">Open Play</th>
              </tr>
              <tr>
                <th className="border-gray-700 text-left">Season / Comp</th>
                <th className="bg-messi-darken5">M<span class="hidden md:inline">essi</span> <span className="sr-only">Messi total assists</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R<span class="hidden md:inline">onaldo</span> <span className="sr-only">Ronaldo total assists</span></th>
                <th className="bg-messi-darken5">M<span class="hidden md:inline">essi</span> <span className="sr-only">Messi set-piece assists</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R<span class="hidden md:inline">onaldo</span> <span className="sr-only">Ronaldo set-piece assists</span></th>
                <th className="bg-messi-darken5">M<span class="hidden md:inline">essi</span> <span className="sr-only">Messi open play assists</span></th>
                <th className="bg-ronaldo-darken5">R<span class="hidden md:inline">onaldo</span> <span className="sr-only">Ronaldo open play assists</span></th>
              </tr>
              </thead>
              <tbody>
              {bothData.map(
                d => (
                  <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold bg-gray-800' : ''}`}>
                    <td className="text-left whitespace-no-wrap border-gray-700">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                    {this.state.p90 ? (
                      <>
                        <td className={`${statPer90(d.mnode.assists,d.mnode.minsPlayed) > statPer90(d.rnode.assists,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.assists,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.assists,d.rnode.minsPlayed) > statPer90(d.mnode.assists,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.assists,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.setPieceAssists,d.mnode.minsPlayed) > statPer90(d.rnode.setPieceAssists,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.setPieceAssists,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.setPieceAssists,d.rnode.minsPlayed) > statPer90(d.mnode.setPieceAssists,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.setPieceAssists,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.openPlayAssists,d.mnode.minsPlayed) > statPer90(d.rnode.openPlayAssists,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.openPlayAssists,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.openPlayAssists,d.rnode.minsPlayed) > statPer90(d.mnode.openPlayAssists,d.mnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.rnode.openPlayAssists,d.rnode.minsPlayed)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className={`${Number(d.mnode.assists) > Number(d.rnode.assists) ? 'text-highlight' : ''}`}>
                          {d.mnode.assists}
                        </td>
                        <td className={`${Number(d.rnode.assists) > Number(d.mnode.assists) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.assists}
                        </td>
                        <td className={`${Number(d.mnode.setPieceAssists) > Number(d.rnode.setPieceAssists) ? 'text-highlight' : ''}`}>
                          {d.mnode.setPieceAssists}
                        </td>
                        <td className={`${Number(d.rnode.setPieceAssists) > Number(d.mnode.setPieceAssists) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.setPieceAssists}
                        </td>
                        <td className={`${Number(d.mnode.openPlayAssists) > Number(d.rnode.openPlayAssists) ? 'text-highlight' : ''}`}>
                          {d.mnode.openPlayAssists}
                        </td>
                        <td className={`${Number(d.rnode.openPlayAssists) > Number(d.mnode.openPlayAssists) ? 'text-highlight' : ''}`}>
                          {d.rnode.openPlayAssists}
                        </td>
                      </>
                    )}
                  </tr>
                )
              )}
              <tr>
                <th className="text-highlight">Totals</th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.assists)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.assists)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.setPieceAssists)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.setPieceAssists)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.openPlayAssists)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.openPlayAssists)) }, 0)}
                </th>
              </tr>
              </tbody>
            </table>
          </div>


          <h2>Assists by Type</h2>
          <p className="text-highlight">NOTE: an assist can be multiple types (e.g. one assist can be both a free kick and a cross)</p>

          <div className="" onChange={this.manageP90Toggle}>
            <label className={`flex w-full items-center p-2 cursor-pointer`}>
              <input type="checkbox" checked={this.state.p90 ? true : false} />
              <span className="ml-2">Per 90 Stats</span>
            </label>
          </div>

          <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
            <table className="w-full">
              <thead className="text-left">
              <tr>
                <th className="border-gray-700"></th>
                <th className="border-gray-700" colSpan="2">Corner</th>
                <th className="border-gray-700" colSpan="2">Free Kick</th>
                <th className="border-gray-700" colSpan="2">Throw-in</th>
                <th className="border-gray-700" colSpan="2">Throughball</th>
                <th className="border-gray-700" colSpan="2">Cross</th>
                <th colSpan="2">Other</th>
              </tr>
              <tr>
                <th className="border-gray-700 text-left">Season / Comp</th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi corner assists</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R <span className="sr-only">Ronaldo corner assists</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi free kick assists</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R <span className="sr-only">Ronaldo free kick assists</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi throw-in assists</span></th>
                <th className="bg-ronaldo-darken5">R <span className="sr-only">Ronaldo throw-in assists</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi throughball assists</span></th>
                <th className="bg-ronaldo-darken5">R <span className="sr-only">Ronaldo throughball assists</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi cross assists</span></th>
                <th className="bg-ronaldo-darken5">R <span className="sr-only">Ronaldo cross assists</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi other assists</span></th>
                <th className="bg-ronaldo-darken5">R <span className="sr-only">Ronaldo other assists</span></th>
              </tr>
              </thead>
              <tbody>
              {bothData.map(
                d => (
                  <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold bg-gray-800' : ''}`}>
                    <td className="text-left whitespace-no-wrap border-gray-700">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                    {this.state.p90 ? (
                      <>
                        <td className={`${statPer90(d.mnode.cornerAssists,d.mnode.minsPlayed) > statPer90(d.rnode.cornerAssists,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.cornerAssists,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.cornerAssists,d.rnode.minsPlayed) > statPer90(d.mnode.cornerAssists,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.cornerAssists,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.freeKickAssists,d.mnode.minsPlayed) > statPer90(d.rnode.freeKickAssists,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.freeKickAssists,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.freeKickAssists,d.rnode.minsPlayed) > statPer90(d.mnode.freeKickAssists,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.freeKickAssists,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.throwinAssists,d.mnode.minsPlayed) > statPer90(d.rnode.throwinAssists,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.throwinAssists,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.throwinAssists,d.rnode.minsPlayed) > statPer90(d.mnode.throwinAssists,d.mnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.rnode.throwinAssists,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.throughballAssists,d.mnode.minsPlayed) > statPer90(d.rnode.throughballAssists,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.throughballAssists,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.throughballAssists,d.rnode.minsPlayed) > statPer90(d.mnode.throughballAssists,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.throughballAssists,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.crossAssists,d.mnode.minsPlayed) > statPer90(d.rnode.crossAssists,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.crossAssists,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.crossAssists,d.rnode.minsPlayed) > statPer90(d.mnode.crossAssists,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.crossAssists,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.otherAssists,d.mnode.minsPlayed) > statPer90(d.rnode.otherAssists,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.otherAssists,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.otherAssists,d.rnode.minsPlayed) > statPer90(d.mnode.otherAssists,d.mnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.rnode.otherAssists,d.rnode.minsPlayed)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className={`${Number(d.mnode.cornerAssists) > Number(d.rnode.cornerAssists) ? 'text-highlight' : ''}`}>
                          {d.mnode.cornerAssists}
                        </td>
                        <td className={`${Number(d.rnode.cornerAssists) > Number(d.mnode.cornerAssists) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.cornerAssists}
                        </td>
                        <td className={`${Number(d.mnode.freeKickAssists) > Number(d.rnode.freeKickAssists) ? 'text-highlight' : ''}`}>
                          {d.mnode.freeKickAssists}
                        </td>
                        <td className={`${Number(d.rnode.freeKickAssists) > Number(d.mnode.freeKickAssists) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.freeKickAssists}
                        </td>
                        <td className={`${Number(d.mnode.throwinAssists) > Number(d.rnode.throwinAssists) ? 'text-highlight' : ''}`}>
                          {d.mnode.throwinAssists}
                        </td>
                        <td className={`${Number(d.rnode.throwinAssists) > Number(d.mnode.throwinAssists) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.throwinAssists}
                        </td>
                        <td className={`${Number(d.mnode.throughballAssists) > Number(d.rnode.throughballAssists) ? 'text-highlight' : ''}`}>
                          {d.mnode.throughballAssists}
                        </td>
                        <td className={`${Number(d.rnode.throughballAssists) > Number(d.mnode.throughballAssists) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.throughballAssists}
                        </td>
                        <td className={`${Number(d.mnode.crossAssists) > Number(d.rnode.crossAssists) ? 'text-highlight' : ''}`}>
                          {d.mnode.crossAssists}
                        </td>
                        <td className={`${Number(d.rnode.crossAssists) > Number(d.mnode.crossAssists) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.crossAssists}
                        </td>
                        <td className={`${Number(d.mnode.otherAssists) > Number(d.rnode.otherAssists) ? 'text-highlight' : ''}`}>
                          {d.mnode.otherAssists}
                        </td>
                        <td className={`${Number(d.rnode.otherAssists) > Number(d.mnode.otherAssists) ? 'text-highlight' : ''}`}>
                          {d.rnode.otherAssists}
                        </td>
                      </>
                    )}
                  </tr>
                )
              )}
              <tr>
                <th className="text-highlight">Totals</th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.cornerAssists)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.cornerAssists)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.freeKickAssists)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.freeKickAssists)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.throwinAssists)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.throwinAssists)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.throughballAssists)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.throughballAssists)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.crossAssists)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.crossAssists)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.otherAssists)) }, 0)}
                </th>
                <th className="text-ronaldo">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.otherAssists)) }, 0)}
                </th>
              </tr>
              </tbody>
            </table>
          </div>

          <p>If you have any questions about assist stats, contact us on Twitter at <a className="text-highlight underline" target="_blank" rel="noreferrer" href="https://twitter.com/mvsrapp">@mvsrapp</a>, and we’ll do our best to help.</p>

        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          freeKicks
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          freeKicks
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetMessiCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetRonaldoCalYearStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
        }
      }
    }
    allSheetMessiAdditionalSeasonStats {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
          cornerAssists
          freeKickAssists
          throughballAssists
          crossAssists
          throwinAssists
          otherAssists
          setPieceAssists
          openPlayAssists
        }
      }
    }
    allSheetRonaldoAdditionalSeasonStats {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          assists
          cornerAssists
          freeKickAssists
          throughballAssists
          crossAssists
          throwinAssists
          otherAssists
          setPieceAssists
          openPlayAssists
        }
      }
    }
  }
`

export default OptaAssists
